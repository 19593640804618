<template>
  <div>
    <v-avatar color="accent" :size="size">
      <div
        class="image"
        :style="{ background: gradient, width: sizes, height: sizes }"
      />
    </v-avatar>
  </div>
</template>

<script>
  const tinycolor = require("tinycolor2");

  export default {
    props: {
      name: {
        type: String,
        default: "",
      },

      size: {
        type: Number,
        default: 100,
      },

      radius: {
        type: Number,
        default: 40,
      },
    },

    data() {
      return {
        colors: [],
        gradient: "",
      };
    },

    watch: {
      name(e) {
        this.colorsGenerator();
        this.gradientGenerator();
      },
    },

    computed: {
      sizes() {
        return this.size + "px";
      },
    },

    methods: {
      colorsGenerator() {
        this.colors = [];
        
        const tc = tinycolor({
          h: this.hashCode(this.name) % 360,
          s: 0.95,
          l: 0.5,
        });
        this.colors.push(tc.toHexString());
        this.colors.push(tc.triad()[1].toHexString());
        this.colors.push(tc.triad()[2].toHexString());
      },

      gradientGenerator() {
        this.gradient = `linear-gradient(${this.radius}deg, ${this.colors.map(
          (e) => {
            return e;
          }
        )})`;
      },

      hashCode(str, seed = 5) {
        let h1 = 0xdeadbeef ^ seed;
        let h2 = 0x41c6ce57 ^ seed;

        for (let i = 0, ch; i < str.length; i++) {
          ch = str.charCodeAt(i);
          h1 = Math.imul(h1 ^ ch, 2654435761);
          h2 = Math.imul(h2 ^ ch, 1597334677);
        }

        h1 =
          Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
          Math.imul(h2 ^ (h2 >>> 13), 3266489909);
        h2 =
          Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
          Math.imul(h1 ^ (h1 >>> 13), 3266489909);

        return 4294967296 * (2097151 & h2) + (h1 >>> 0);
      },
    },

    mounted() {
      this.colorsGenerator();
      this.gradientGenerator();
    },
  };
</script>

<style scoped>
  .image {
    filter: blur(8px);
  }
</style>
