<template slot="md-tab">
  <div class="body-wrap content">
    <div>
      <div class="header d-flex justify-space-between align-center mt-2 mb-10">
        <router-link class="mr-auto" to="/">
          <img src="../assets/logo.svg?data" id="logo" />
          <img src="../assets/mobile-logo.svg?data" id="mobile-logo" />
        </router-link>

        <div class="main-menu px-7">
          <router-link
            :class="{ 'active-link': $route.path === '/wallets' }"
            to="/wallets"
            >Portfele</router-link
          >
        </div>

        <Header />
      </div>

      <v-container>
        <wallet-amount />
        <slot v-if="web3.account" />
        <div v-else class="d-flex flex-column align-center text-center py-16">
          <div class="content d-flex justify-center align-center">
            <div class="d-flex flex-column align-center">
              <v-btn
                @click="web3.web3Modal.toggleModal()"
                class="create-btn"
                outlined
                text
                >Connect to wallet</v-btn
              >
              <div class="py-5">Aby kontynułować połącz się z portfelem.</div>
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
  import Header from "../components/header";
  import WalletAmount from "../components/walletAmount";

  export default {
    components: {
      Header,
      WalletAmount,
    },

    data() {
      return {};
    },

    computed: {
      web3() {
        return this.$store.state.web3;
      },
    },
  };
</script>

<style lang="scss">
.kPFXFE.web3modal-modal-card{
  max-width: 600px;
}
  .create-btn {
    height: 56px !important;
    background: rgba(212, 175, 55, 0.87);
    color: white !important;
    border: transparent;
  }

  .header {
    max-height: 40px;
  }

  #logo {
    max-width: 200px;
  }

  @media (min-width: 601px) {
    #mobile-logo {
      display: none;
    }
  }
  @media (max-width: 600px) {
    #logo {
      display: none;
    }

    #mobile-logo {
      display: block;
      height: 50px;
    }
  }

  .wrap {
    width: 100%;
    margin: 0px auto;
    max-width: 1050px;
  }

  .content {
    min-height: calc(100vh - 255px);
  }

  @media (max-width: 780px) {
    .main-menu {
      display: none;
    }
  }

  .active-link {
    background: #edf0f3;
  }

  .main-menu {
    a {
      text-decoration: none;
      display: block;
      position: relative;
      font-size: 17px;
      font-weight: 500;
      color: #222531 !important;
      overflow: hidden;
      border-radius: 8px;
      padding: 3px 8px 3px 8px;
      margin: 3px 3px;
    }

    a:hover {
      background: #edf0f3;
    }
  }

  .body-wrap{
    .container {
  width: 100%;
  padding: 12px;
  margin-right: auto;
  margin-left: auto;

    font-family: inherit;
    color: inherit;
    text-align: inherit;
    box-sizing: inherit;
    display: inherit;
    flex-direction: inherit;
    flex-grow: inherit;
    flex-shrink: inherit;
    justify-content: inherit;
    max-height: inherit;
    top: inherit;
position: inherit;
    max-width: inherit;
}
p{
     margin: 0;
    padding-bottom: inherit;   
}
}
</style>
