//import {getLibrary} from "@/utils/web3";
import { ethers } from "ethers";
import blockchain from "./../plugins/blockchain.js";
var waitingForClear = false;
var waitingForConnect = false;
const web3ModalStore = {
  state: {
    web3Modal: null,
    library: null, // getLibrary(),
    active: false,
    account: null,
    balance: "",
    chainId: 0,
    proprosals: [],
    contractBalance: 0,
    mainGoalBalance: 0
  },
  mutations: {
    setWeb3Modal(state, web3Modal) {

      state.web3Modal = web3Modal;
      if (web3Modal.cachedProvider) {
        //this.dispatch("connect");
      }
      web3Modal.providerController.on("connect", (provider) => {
        if (!state.active) {
          this.dispatch("connect");
        }
      });
    },
    setLibrary(state, library) {
      state.library = library;
    },
    setActive(state, active) {
      state.active = active;
    },
    setAccount(state, account) {
      state.account = account == null? null : account.toLowerCase();
      if (account == null) {
        state.balance = "";
      }
    },
    setBalance(state, balance) {
      state.balance = balance;
    },
    setChainId(state, chainId) {
      state.chainId = chainId;
    },  
    setContractInfo(state, data){
      state.contractBalance = parseFloat(data.TotalWei);
      state.mainGoalBalance = parseFloat(data.MainGoalWei);
    },
    getProprosals(state, count) {
      this.dispatch("getProprosals", count).then((proprosals) => {
        state.proprosals = proprosals;
      });
    },
  },

  getters: {
    companyBalance(state){
      return state.contractBalance - state.mainGoalBalance;
    }
  },

  actions: {
    async connect({ state, commit, dispatch }) {
      if(state.active || waitingForConnect) return;
      waitingForConnect = true;
      const provider = await state.web3Modal.connect();
      const library = new ethers.providers.Web3Provider(provider);

      library.pollingInterval = 12000;
      commit("setLibrary", library);

      const accounts = await library.listAccounts();
      if (accounts.length > 0) {
        commit("setAccount", accounts[0]);
        let balance = await library.getBalance(accounts[0]);
        balance = (parseFloat(balance.toString()) / 10 ** 18).toFixed(4);
        commit("setBalance", balance);
      }
      const network = await library.getNetwork();
      commit("setChainId", network.chainId);
      commit("setActive", true);

      provider.on("disconnected", (info) => {
        dispatch("resetApp")
      });

      provider.on("disconnect", (code, reason) => {
        dispatch("resetApp")
      });

      provider.on("connect", async (info) => {
        let chainId = parseFloat(info.chainId);
        commit("setChainId", chainId);
      });

      provider.on("accountsChanged", async (accounts) => {
        if (accounts.length > 0) {
          commit("setAccount", accounts[0]);
          let balance = await library.getBalance(accounts[0]);
          balance = (parseFloat(balance.toString()) / 10 ** 18).toFixed(4);
          commit("setBalance", balance);
        } else {
          await dispatch("resetApp");
        }
      });
      provider.on("chainChanged", async (chainId) => {
        chainId = parseFloat(chainId);
        commit("setChainId", chainId);
      });
    },
    async resetApp({ state, commit }) {
      waitingForConnect = false;
      if(!waitingForClear)
        console.log("resetApp");
    try {
      state.web3Modal.disconnect();
      if(state.web3Modal.cachedProvider){
        waitingForClear = true;
        await state.web3Modal.clearCachedProvider();
        waitingForClear = false;
      }
    } catch (error) {
      waitingForClear = false;
      console.error(error);
    }

      commit("setAccount", null);
      commit("setActive", false);
    },

    async getProprosals(state, params) {
      const proprosals = [];
      const contractState = await blockchain.call("info");
      this.commit("setContractInfo", contractState);
      const proposalsLength = await blockchain.call("proposalsLength");

      for (let i = 0; i < Math.max(0, proposalsLength); i++) {
        let id = i;
        const result = await blockchain.call("getProposal", id);
        proprosals.push({
          actionAddress: result.actionAddress,
          addresses: result.addresses,
          actionType: parseFloat(result.actionType),
          amount: parseFloat(result.amount),
          endTime: parseFloat(result.endTime * 1000),
          id: id,
          percents: result.percents,
          result: parseFloat(result.result),
          voters: result.voters,
          votes: result.votes.map(parseFloat),
        });
      }

      const sorted = proprosals.sort((a, b) => b.id - a.id);

      const filteredProprosals = sorted.filter((obj) => {
        return obj.actionType === params.actionType;
      });

      return [...filteredProprosals.slice(0, params.count)];
    },
  },
};
export default web3ModalStore;
