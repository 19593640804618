<template>
  <div class="menu d-flex">
    <div v-if="web3.active" class="wallet-amount d-flex align-center mr-3">
      {{ web3.balance }} ETH
    </div>
    <div class="metamask">
      <div class="d-flex flex-row">
        <span v-if="web3.active">{{ shortAddress(web3.account) }}</span>
        <span v-else @click="openModal">Connect</span>
        <Avatar
          v-if="web3.active"
          class="pl-2"
          :size="25"
          :name="web3.account"
        />
      </div>
    </div>
    <v-menu content-class="mini-menu" bottom open-on-hover offset-y left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="ml-2" v-bind="attrs" v-on="on" icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <div class="d-flex flex-column">
        <a href="https://decentral-art.com/">Decentral Art</a>
        <v-divider class="my-1"></v-divider>
        <router-link to="/">Głosowania</router-link>
        <router-link to="/wallets">Portfele</router-link>
        <a v-if="web3.active" @click="$store.dispatch('resetApp')"
          >Disconnect</a
        >
        <a v-else @click="openModal">Connect</a>
      </div>
    </v-menu>
  </div>
</template>

<script>
  import Avatar from "./avatar";

  export default {
    components: {
      Avatar,
    },

    data() {
      return {};
    },

    computed: {
      web3() {
        return this.$store.state.web3;
      },
    },

    methods: {
      shortAddress(address) {
        return (
          address.substr(0, 6) + " … " + address.substr(address.length - 4, 4)
        );
      },

      openModal() {
        this.web3.web3Modal.toggleModal();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .metamask {
    border-radius: 4px;
    box-shadow: 0.5px 0.9px 5px 0 rgba(0, 0, 0, 0.1);
    background: #edf0f3;
    border: solid 1px #ebebeb;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
    cursor: pointer;
    padding: 5px 10px 5px 10px;

    div {
      align-items: center;
      font-weight: 500;
    }
  }

  .wallet-amount {
    font-size: 17px;
    font-weight: 500;
  }

  @media (max-width: 425px) {
    .wallet-amount {
      display: none !important;
    }
  }

  .mini-menu {
    padding: 5px 5px;
    background: white;

    a {
      text-decoration: none;
      display: block;
      position: relative;
      font-size: 14px;
      font-weight: 500;
      color: #222531;
      padding: 6px 12px 6px 6px;
      margin: 3px 3px;
      border-radius: 6px;
      &:hover {
        background: #edf0f3;
      }
      span {
        width: 20px;
        display: inline-block;
        text-align: center;
        margin-right: 4px;
        vertical-align: sub;
      }
    }
  }
</style>
