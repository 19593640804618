import Vue from "vue";
import Vuex from "vuex";
import web3ModalStore from "./web3ModalStore";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    auth: getSavedState("auth"),
  },

  getters: {
    loggedIn(state) {
      return !!state.auth;
    },
  },

  mutations: {
    SET_LOGGED_IN(state, value) {
      state.auth = value;
      saveState("auth", value);
    },
  },

  actions: {},

  modules: {
    web3: web3ModalStore,
  },
});

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
};
