<template>
  <div class="wrap top pt-10">
    <div class="top-left">
      <div class="top-1">Portfel firmowy</div>
      <div class="top-2">{{ companyBalance }} eth</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {},

    data() {
      return {};
    },

    computed: {
      companyBalance() {
        return (this.$store.getters.companyBalance / 10 ** 18).toFixed(4);
      },
    },

    methods: {},
  };
</script>

<style lang="scss">
  @media (max-width: 600px) {
    .top-left {
      transform: scale(0.8) !important;
    }

    .top {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .top {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .top div + div {
    flex: 1;
  }
  .top-left {
    max-width: 326px;
  }
  .top-1 {
    font-size: 14px;
    font-weight: bold;
    color: #58667e;
    text-transform: uppercase;
  }
  .top-2 {
    font-size: 40px;
    font-weight: bold;
    color: #d4af37;
  }
</style>
