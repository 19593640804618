//import web3 from "web3";
import Web3ModalVue from "web3modal-vue";

import WalletConnectProvider from "@walletconnect/web3-provider";
import MewConnect from "@myetherwallet/mewconnect-web-client";

let vueInstance = null;
const ABI = require("./../assets/DecentralArt.json");
const contractAddress = "0x7c620D582a6Eae9635E4CA4B9A2b1339F20EE1f2";
const infuraId = "90952d0af11c4bccbcee97c79100061d";
const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: infuraId,
      apiKey: '14cbc8df17198727fd10df3f7dc0c997',
      rpc: {
        1337: "https://decentral-art.com/wss",
      },
    },
  },
  mewconnect: { 
    package: MewConnect, // required
    options: {
      //infuraId: infuraId, // required
      network: "mainnet",
      rpc: "wss://mainnet.infura.io/ws/v3/90952d0af11c4bccbcee97c79100061d",
    },
  },
};

const Contract = require("web3-eth-contract");
let myContract = null;

const parseEvent = function (error, event) {};

const registerContract = (library) => {
  if (!myContract) {
    Contract.setProvider(library.provider);
    myContract = new Contract(ABI, contractAddress);
    //console.log(myContract, contractAddress);
    /*myContract.events.allEvents({}, parseEvent);
    myContract.getPastEvents('allEvents', {
      fromBlock: 0,
      topics: [
        "0x534f1d8d2c3b462b484917bfaed9564a69bfc7f31710cd9f5071d8881d5c78d0", // ProposalCreated
      ]
    }, (error, events) => console.log(error,events));*/
  }
};

const Call = function (method, ...params) {
  registerContract(vueInstance.$store.state.web3.library);
  let callParams = {},
    account = vueInstance.$store.state.web3.account;
  if (account != "") {
    callParams = { from: account };
  }
  return myContract.methods[method](...params).call(callParams);
};

const Send = function (method, sendParams, ...params) {
  registerContract(vueInstance.$store.state.web3.library);
  let callParams = {},
    account = vueInstance.$store.state.web3.account;
  if (account != "") {
    sendParams = Object.assign(sendParams, { from: account });
  }
  return myContract.methods[method](...params).send(sendParams);
};

const Blockchain = {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    Vue.prototype.$web3Call = Call;
    Vue.prototype.$web3Send = Send;
    Vue.prototype.$web3ProviderOptions = providerOptions;
    Vue.mixin({
      beforeCreate() {
        vueInstance = this;
      }
    });
  },  
  call: Call,
  send: Send
};

export default Blockchain;
